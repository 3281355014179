.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.tabsContainer {
  flex: 1;
  height: 100%;
}
