.tableRowTaskNameWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tableRowTaskName {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableRowTaskType {
  background-color: #f9f0ff;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid #d3adf7;
  padding-right: 12px;
  width: fit-content;
}

.tableRowActions {
  display: flex;
  align-items: center;
  gap: 4px;
}
