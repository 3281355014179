.uploadItem {
  background-color: var(--neutral-bg-light-colorBgLayout);
  padding: var(--padding);
  border-radius: var(--borderRadiusLG);
  margin-bottom: 0.5rem;
  position: relative;
}

.uploadItemWithClose {
  padding-top: 2rem;
}

.uploadItem :global(.ant-form-item-row) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: var(--marginXXS) var(--marginSM);
}

/* Para el centrado de los labels */
.uploadItem :global(.ant-form-item-label) {
  padding: 0;
  font-weight: 600;
}

/* Para que los botones `Upload` queden al final */
.uploadItem :global(.ant-form-item-control) {
  width: fit-content !important;
  flex-grow: 0;
}

.uploadItemFilename {
  margin: 0;
  margin-left: var(--marginSM);
  max-width: 92%;
  display: flex;
  gap: var(--marginXS);
}

.uploadItemFilenameText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uploadItemJustification {
  border: 1px solid var(--neutral-border-light-colorBorderSecondary);
  border-radius: var(--borderRadius);
  padding-top: var(--paddingXS);
  padding-bottom: var(--paddingXS);
  margin-top: var(--marginXS);
}

.borderError {
  border-color: var(--brand-warning-light-colorWarningBorder);
}

.uploadItemError {
  display: flex;
  gap: 0.5rem;
  margin: 0;
  margin-left: 11px;
  margin-top: 8px;
  max-width: 92%;
  white-space: wrap;
  overflow-wrap: break-word;
  word-break: break-all;
}

.exclamationCircleWarning {
  color: var(--brand-warning-light-colorWarning);
  font-size: 1rem;
}
