.leftAside {
  background-color: #fff;
  position: relative;
  height: 100vh;
  max-width: 288px;
}

.menu {
  height: 100%;
}

.menuFooter {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  display: flex;
  margin-bottom: 12px;
  width: 100%;
  transition: all 300ms;
}

.menuFooterCollapsed {
  flex-direction: column;
  margin-left: 11px;
  gap: 10px;
  transition: all 300ms;
}

.menuFooterUncollapsed {
  justify-content: space-between;
  transition: all 300ms;
}
