@font-face {
  font-family: Manrope;
  src: url(./assets/fonts/Manrope-VariableFont_wght.ttf);
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-VariableFont_opsz,wght.ttf);
}

:root {
  /* Size */
  --sizeStep: 4px;
  --sizeUnit: 4px;
  --controlInteractiveSize: 16px;
  --sizePopupArrow: 16px;

  /* Size / Base */
  --sizeXXS: 4px;
  --sizeXS: 8px;
  --sizeSM: 12px;
  --size: 16px;
  --sizeMS: 16px;
  --sizeMD: 20px;
  --sizeLG: 24px;
  --sizeXL: 32px;
  --sizeXXL: 48px;

  /* Size / Height */
  --controlHeight: 32px;
  --controlHeightLG: 40px;
  --controlHeightSM: 24px;
  --controlHeightXS: 16px;

  /* Size / Line Width */
  --lineWidth: 1px;
  --lineWidthBold: 2px;
  --lineWidthFocus: 4px;
  --controlOutlineWidth: 2px;

  /* Space / Padding */
  --padding: var(--size);
  --paddingLG: var(--sizeLG);
  --paddingMD: var(--sizeMD);
  --paddingSM: var(--sizeSM);
  --paddingXL: var(--sizeXL);
  --paddingXXL: var(--sizeXXL);
  --paddingXS: var(--sizeXS);
  --paddingXXS: var(--sizeXXS);
  --paddingContentHorizontal: var(--sizeMS);
  --paddingContentHorizontalLG: var(--sizeLG);
  --paddingContentHorizontalSM: var(--size);
  --paddingContentVertical: var(--sizeSM);
  --paddingContentVerticalLG: var(--sizeMS);
  --paddingContentVerticalSM: var(--sizeXS);
  --controlPaddingHorizontal: 12px;
  --controlPaddingHorizontalSM: 8px;

  /* Space / Margin */
  --margin: var(--size);
  --marginLG: var(--sizeLG);
  --marginMD: var(--sizeMD);
  --marginSM: var(--sizeSM);
  --marginXL: var(--sizeXL);
  --marginXS: var(--sizeXS);
  --marginXXL: var(--sizeXXL);
  --marginXXS: var(--sizeXXS);

  /* Border Radius */
  --borderRadius: 8px;
  --borderRadiusLG: 12px;
  --borderRadiusSM: 4px;
  --borderRadiusXS: 2px;

  /* Shadow (drop-shadow) */

  --boxShadow: 0px 2px 4px 0px #000000;
  --boxShadowSecondary: 0px 6px 16px 0px rgba(0, 0, 0, 0.05),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  /* Component */
  --inputActiveShadow: 0px 0px 0px 2px #0591ff;
  --inputWarningActiveShadow: 0px 0px 0px 2px #ffd705;

  --buttonDangerShadow: 0px 2px 0px 0px #ff2606;
  --buttonDefaultShadow: 0px 2px 0px 0px #f5f5f6;
  --buttonPrimaryShadow: 0px 2px 0px 0px #0591ff;
  --buttonErrorActiveShadow: 0px 0px 0px 2px #ff2606;

  --switchHandleShadow: 0px 2px 4px 0px #00230b;

  /*** Colors ***/
  --neutral-bg-light-colorBgLayout: #fbfbff;
  --neutral-bg-light-colorBgContainer: #ffffff;
  --neutral-bg-light-colorBgCardFeedback: #fafafb;

  --neutral-border-light-colorBorderSecondary: #e6e6e7;
  --neutral-border-light-colorSplit: #f5f5f6;

  --neutral-text-light-colorTextDisabled: #b4b5b6;
  --neutral-text-light-colorText: #222223;
  --neutral-text-light-colorTextSecondary: #444446;
  --neutral-text-light-colorTextTertiary: #808085;

  --brand-link-light-colorLink: #0161fe;
  --brand-primary-light-colorPrimaryBg: #f0f6ff;
  --brand-primary-light-colorPrimaryBgHover: #dbe9ff;
  --brand-error-light-colorErrorBorder: #ffccc7;
  --brand-warning-light-colorWarning: #faad14;
  --brand-warning-light-colorWarningBorder: #ffe58f;

  --neutral-fill-light-colorFillQuaternary: #f5f5f6;

  --Gray-gray-7: #8c8c8c;
}

/* Estilos para la scrollbar en general */
::-webkit-scrollbar {
  width: 11.5px; /* Ancho de la scrollbar */
  height: 11.5px; /* Altura para scrollbars horizontales */
}

/* Estilos para el track (la parte que no se desplaza) */
::-webkit-scrollbar-track {
  background: #fff; /* Color de fondo */
  border-radius: 10px; /* Bordes redondeados */
}

/* Estilos para el thumb (la parte que se desplaza) */
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px; /* Bordes redondeados */
  border: 3px solid #fff;
}

body {
  font-family: Inter, Helvetica, Arial;
}

/* Generics */

.base {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5%;
}

.sm {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5%;
}

.lg {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

.xl {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
}

.normal {
  font-weight: 400;
}

.strong {
  font-weight: 600;
}

.underline {
  text-decoration: underline;
}

.delete {
  text-decoration: line-through;
}

.head1 {
  font-size: 38px;
  font-weight: 500;
  line-height: 46px;
}

.head2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
}

.head3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.head4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.head5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.flex-r-center {
  display: flex;
  align-items: center;
}

.w-full {
  width: 100%;
}

.m-0 {
  margin: 0;
}

.ellipsed {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.one-line {
  -webkit-line-clamp: 1; /* Número de líneas */
}

.two-lines {
  -webkit-line-clamp: 2; /* Número de líneas */
}

.rotate {
  transition: transform 300ms;
}

.rotate.up {
  transform: rotate(180deg);
}

/* Custom Antd Classes */

/* Modificamos los colores de los errores en los Form.Item */
.customFormItem
  > div.ant-form-item-row
  > div.ant-form-item-control
  > div:last-child
  > div.ant-form-item-explain
  > div.ant-form-item-explain-error {
  color: #000;
}

/* Modificamos el margen inferior de los errores en los Inputs del login */
.customFormItem.login
  > div.ant-form-item-row
  > div.ant-form-item-control
  > div:last-child
  > div.ant-form-item-explain
  > div.ant-form-item-explain-error:last-child {
  margin-bottom: var(--marginLG);
}

/* Modificamos el el espacio entre lineas de texto en los errores de los filtros */
.customFormItem.filters
  > div.ant-form-item-row
  > div.ant-form-item-control
  > div:last-child
  > div.ant-form-item-explain
  > div.ant-form-item-explain-error {
  line-height: 1.3;
}

/* Modificamos el margen inferior del Form.Item de los filtros */
.filtersFormInput {
  margin-bottom: var(--margin);
}

/* Modificamos el espaciado entre el label y el input de los filtros */
.filtersFormInput > div.ant-form-item-row > div.ant-form-item-label {
  padding-bottom: var(--paddingXXS);
}

.customTabBar {
  height: 100%;
}

/* Elimina el border de los tabs por default */
.customTabBar > div.ant-tabs-nav::before {
  border-bottom: none;
}

.customTabBar > div.ant-tabs-nav {
  padding: var(--paddingSM) var(--paddingLG);
}

/* Cambia el font-size de las tabs */
.customTabBar
  > div.ant-tabs-nav
  > div.ant-tabs-nav-wrap
  > div.ant-tabs-nav-list
  > div.ant-tabs-tab {
  font-size: var(--size);
}

/* Para el background que contiene la tabla se expanda hasta el footer */
.customTabBar > div.ant-tabs-content-holder > div.ant-tabs-content-top {
  height: 100%;
}

.customTabBar
  > div.ant-tabs-content-holder
  > div.ant-tabs-content-top
  > div[role="tabpanel"].ant-tabs-tabpane:not(.ant-tabs-tabpane-hidden) {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Modificamos el padding del content de la Card */
.customCard > div.ant-card-body {
  padding: var(--padding) var(--paddingLG);
}

/* Modificamos el titulo/header de la tabla */
.customTable
  > div.ant-spin-nested-loading
  > div.ant-spin-container
  > div.ant-table
  > div.ant-table-title {
  background-color: var(--neutral-bg-light-colorBgLayout);
  padding: 8px 0;
}

/* Modificamos las rows de la tabla para que tengan el cursor pointer */
.customTableRow {
  cursor: pointer;
}

/* Modificamos los titulos de las columnas */
th.ant-table-cell {
  font-size: 14px;
}

/* Modificamos el boton de expandido de las rows de la tabla */
button.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded,
button.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
  border-radius: var(--borderRadiusXS);
}

/* Modificamos los links de la tabla */
.documentLink {
  color: var(--neutral-text-light-colorText);
}

.documentLink:hover {
  color: var(--brand-link-light-colorLink);
}

/* Modificamos paginador tabla */
.ant-pagination {
  gap: var(--marginXXS);
}

/* Ocultamos el porcentaje del progress */
.noPercentage > div.ant-progress-steps-outer > span.ant-progress-text {
  display: none;
}

/* Modificamos el tamaño de la fuente de los numeros del paginador de la tabla */
.ant-pagination > li.ant-pagination-item {
  font-size: 14px;
}

/* Modificamos el padding del modal de filtros */
.filtersFormModal > div[tabindex="-1"] > div.ant-modal-content {
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-bottom: var(--paddingXS);
}

.filtersTag {
  border-radius: 12px;
  display: flex;
  width: fit-content;
  padding: 1px 10px;
}

.filtersTag > span.anticon {
  color: var(--neutral-text-light-colorText);
  font-size: 12px;
}

.customEmptyTable {
  margin: 2rem auto;
  max-width: 320px;
  height: calc(100% - var(--header-height) - var(--paddingXS));
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.customEmptyTable > div.ant-empty-image {
  margin-bottom: 2rem;
}

/* Modificamos el header del menu de Documents */
.customMenuHeader {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 600;
  cursor: default !important;
  height: 56px !important;
  margin: 0 4px !important;
}

.ant-menu-inline > .customMenuHeader {
  padding-left: 12px !important;
}

.ant-menu-inline-collapsed > .customMenuHeader > a.ant-menu-item-icon {
  margin-top: 0.7rem;
}

.ant-menu-inline-collapsed > .customMenuHeader {
  padding-left: 20px !important;
}

.customMenuHeader > a::before {
  pointer-events: none;
}

.customMenuHeader.ant-menu-item-disabled {
  color: var(--neutral-text-light-colorText) !important;
}

.customMenuHeader > a {
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.customMenuHeader > a:hover {
  background: #e6e6e7;
}

.customMenuHeader > a > span {
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
}

.customMenuHeader > a > span > span.anticon {
  display: inline-flex;
}

.customMenuHeader > span.ant-menu-title-content {
  margin-inline-start: 10px !important;
}

.leftaside-container {
  height: calc(100vh - 96px);
  overflow-y: scroll;
}

.leftaside-container.collapsed {
  height: calc(100vh - 131px);
}

.leftaside-container > div.ant-menu-item-group-title:first-child {
  display: none;
}

/* Modificamos las agrupaciones del menu de Documents */
.customMenuGroup > ul.ant-menu-item-group-list {
  margin: 0 var(--marginSM) !important;
}

/* Modificamos los titulos del menu de Documents */
.customMenuGroup > div.ant-menu-item-group-title {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: var(--marginXXS);
}

.customMenuGroup > ul.ant-menu-item-group-list > li.ant-menu-item {
  padding-inline: 14px 28px !important;
}

.customMenuItemFocus {
  background-color: #e6e6e7;
}

/* Modificamos el margintop del formulario contra el contenedor */
.maskForm {
  margin-top: var(--margin);
}

/* Modificamos el marginbottom del Form.Item de los radioButtons formulario de masks */
.maskForm > div.ant-form-item:first-child {
  margin-bottom: var(--margin);
}

/* Modificamos el padding del Form.Item de los radioButtons formulario de masks */
.maskForm > div.ant-form-item:first-child > div.ant-row > div.ant-col {
  padding: 0;
}

.feedbackCard {
  background-color: var(--neutral-bg-light-colorBgCardFeedback);
  cursor: pointer;
}

.feedbackCard.selected {
  background-color: var(--brand-primary-light-colorPrimaryBg);
}

.feedbackCard > div.ant-card-body {
  padding: var(--padding);
}

.feedbackCard > .ant-card-actions {
  background-color: var(--neutral-fill-light-colorFillQuaternary);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 1rem;
}

.feedbackCard.selected > .ant-card-actions {
  background-color: var(--brand-primary-light-colorPrimaryBgHover);
}

.feedbackCard > .ant-card-actions > li {
  margin: 0;
  text-align: start;
  width: 100% !important;
}

.feedbackCard > .ant-card-actions > li > span:hover {
  color: var(--neutral-text-light-colorText);
}

.masksFormItem {
  margin-bottom: var(--margin);
}

.masksFormItem.feedback > div.ant-row > div.ant-col {
  padding-bottom: 0.25rem;
}

.masksFormItem > div.ant-row > div.ant-col {
  padding-bottom: 0;
}

/* Modal UpdateTask(Reupload) */
.updateModalWrapper > div[role="dialog"] {
  min-height: 992px;
}

.updateModalWrapper > div[role="dialog"] > div[tabindex="-1"],
.updateModalWrapper
  > div[role="dialog"]
  > div[tabindex="-1"]
  > div.ant-modal-content {
  min-height: inherit;
}

.updateModalWrapper
  > div[role="dialog"]
  > div[tabindex="-1"]
  > div.ant-modal-content {
  display: flex;
  align-items: center;
}

.updateModalWrapper
  > div[role="dialog"]
  > div[tabindex="-1"]
  > div.ant-modal-content
  > div.ant-modal-body {
  width: 100%;
}
