.cardList {
  display: grid;
  grid-template-columns: repeat(5, minmax(265px, 1fr));
  gap: var(--padding);
  padding: var(--paddingSM) var(--paddingLG);
}

.card {
  flex: 1;
  height: 100%;
}

.cardContent {
  display: flex;
  gap: var(--paddingXXS);
}

.cardInfoContent {
  flex: 1;
}

.cardImage {
  height: 120px;
  object-fit: cover;
  border: 1px solid #f5f5f6;
}

.cardTitle {
  margin: 0;
  height: 48px;

  /* crear ellipsis */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Número de líneas a mostrar */
  -webkit-box-orient: vertical;
}

.cardId,
.cardUpdatedAt {
  color: var(--neutral-text-light-colorTextTertiary);
}
