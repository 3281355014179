.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: var(--neutral-bg-light-colorBgContainer);
}

/* Wrapper containing Aside & Main*/
.contentWrapper {
  display: flex;
  flex: 1;
}
