.feedbackCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feedbackSection {
  margin-top: 8px;
  margin-bottom: var(--paddingSM);
}

.occurrencesList {
  padding: 0;
  margin-top: 4px;
  margin-bottom: 4px;
}

.textNoOccurrences {
  color: var(--neutral-text-light-colorTextSecondary);
}

.occurrenceItem {
  padding: 0;
  margin: 0;
  border: none;
  background-color: inherit;
  height: auto;
}

.deleted {
  color: var(--neutral-text-light-colorTextDisabled);
  text-decoration: line-through;
}

.actions {
  background-color: #fff;
  padding: var(--paddingSM) var(--padding);
  border-radius: var(--borderRadiusLG);
  border: 1px solid vaR(--neutral-border-light-colorSplit);
}

.cardFooter {
  display: flex;
  gap: 12px;
}

.cardFooterText {
  color: var(--neutral-text-light-colorText);
  margin: 0;
}
