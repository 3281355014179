.formContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  background-color: var(--neutral-bg-light-colorBgContainer);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: var(--paddingXXL);
  width: 480px;
}

/* Form Content */

.formHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin: 0;
  }
}

.formContent {
  display: flex;
  flex-direction: column;
  margin-top: var(--marginXXL);
  margin-bottom: var(--marginLG);
}