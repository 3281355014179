.maskButton {
  display: flex;
  align-items: center;
  margin-bottom: var(--marginXXS);
}

.container {
  position: absolute;
  top: 75px;
  width: 347px;
  right: -365px;
}
