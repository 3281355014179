.modalTitle {
  margin-bottom: var(--marginLG);
}

.inputsSection {
  margin-top: var(--marginMD);
}

.uploadBlock {
  margin-bottom: var(--marginLG);
}

.uploadBlock :global(.ant-form-item) {
  margin: 0;
}

.uploadBlockHelperText {
  position: absolute;
  right: 0;
}

.uploadItemOptionalLabel {
  font-weight: 600;
  margin: 0;

  > span {
    font-weight: initial;
  }
}

.uploadItemButtonClose {
  position: absolute;
  top: 4px;
  right: 4px;
}
