.loadingContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingTitle {
  margin-top: 17px;
  margin-bottom: 9px;
}

.loadingMessage {
  text-align: center;
  max-width: 296px;
  color: var(--neutral-text-light-colorTextTertiary);
}
