.header {
  display: flex;
  align-items: center;
  gap: 1rem;

  border-bottom: 1px solid var(--neutral-border-light-colorBorderSecondary);

  min-height: 56px;
  padding-right: 1.5rem;
}

.hipraLogo {
  padding: 4px 24px;
}

.title {
  margin: 0;
  flex: 1;
}

.dropdownMenuItem {
  display: flex;
  gap: 0.5rem;
  width: 147px;
}
