.container {
  background-color: var(--neutral-bg-light-colorBgLayout);

  display: flex;
  justify-content: space-around;

  height: 100vh;

  background-color: #0aad7805;
}

.bgContent,
.formContent {
  flex: 1;
  padding-bottom: 1.25rem;
}

.bgContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/images/DemoMaskGroup.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.logoHipraAim {
  width: 324px;
  height: 129px;
}

.logoHipra {
  width: 108px;
  height: 44px;
}

.logoHipraAim,
.bgContentFooter,
.logoHipra {
  margin-top: auto;
}

.formContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
