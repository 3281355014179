.rightAside {
  background-color: #fff;
  border-left: var(--default-border);
  color: #000;
  position: relative;
  width: 46%;
  max-width: 394px;
}

.rightAsideHeader {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  padding: var(--paddingSM) var(--padding);
  border-bottom: var(--default-border);
  height: var(--header-height);

  > h4 {
    text-wrap: nowrap;
    font-weight: 400;
    margin: 0;
    width: 160px;
    color: var(--neutral-text-light-colorTextSecondary);
  }
}

.searchContainer {
  display: flex;
  gap: var(--paddingXXS);
  margin-left: 1rem;
}

.spinnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  height: 90%;
}

.spinnerTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.spinnerTextContainer > p {
  margin: 0;
}

.spinnerTextTitle {
  text-align: center;
}

.spinnerTextInfo {
  width: 80%;
  text-align: center;
  color: var(--neutral-text-light-colorTextTertiary);
}

.masksContainer {
  padding: var(--padding);
  height: calc(100% - var(--masks-container-height));
  overflow: auto;
}

.masksList {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  gap: var(--padding);
}

.notificationsList {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.customAlert {
  padding: 1.5rem;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid #e6e6e7;
  padding: 12px 16px;
  display: flex;
  align-items: center;

  > p {
    max-width: 60%;
    margin-bottom: 0;
    color: var(--neutral-text-light-colorTextSecondary, #444446);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
  }

  > button {
    margin-left: auto;
  }
}
