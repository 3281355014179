.container {
  width: 347px;
  background-color: var(--neutral-bg-light-colorBgContainer);
  border-radius: var(--borderRadius);
  padding: 0.5rem;
  box-shadow: var(--boxShadowSecondary);
  position: relative;
}

.closeButton {
  position: absolute;
  right: 7px;
  z-index: 10;
}

.optionalText {
  color: var(--Gray-gray-7);
}

.freeTextInput {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.footer {
  display: flex;
  gap: var(--marginXS);
}
