.container {
  border-radius: 4px;
  background: #fafafb;

  /* boxShadowTertiary */
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.05),
    0px 1px 14px -1px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
  width: 126px;
  display: flex;
  justify-content: center;
  padding: 0.2rem 0.5rem;
}

.tagText {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.blackText {
  color: var(--neutral-text-light-colorText);
}

.greyText {
  color: var(--neutral-text-light-colorTextTertiary);
}
