:root {
  --border-color: #e0e1e6;
  --default-border: 1px solid var(--border-color);
  --header-height: 57.5px;
  --aside-footer-height: 65px;
  --masks-container-height: calc(
    var(--header-height) + var(--aside-footer-height)
  );
}

.container {
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
}

.documentViewer {
  width: 100%;
  height: 100%;
}
