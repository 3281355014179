.aside {
  display: flex;
  flex-direction: column;
  align-items: center;

  border-right: 1px solid var(--neutral-border-light-colorBorderSecondary);

  padding-bottom: var(--padding);
}

.menu {
  flex: 1;
  border: none;
}

.omniosLink {
  color: var(--neutral-text-light-colorTextTertiary) !important;
}
