.tableHeader {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 54px;
  margin-bottom: 8px;
}

.tableHeaderTitle {
  margin: 0;
  color: var(--neutral-text-light-colorText);
}

.tableHeaderStatus {
  color: var(--neutral-text-light-colorTextSecondary);
}

.tableHeaderActions {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: auto;
  margin-right: 6px;
}
