.form {
  margin-top: var(--marginMD);
}

.footer {
  margin-top: 1.5rem;
  display: flex;
  gap: 0.5rem;
}

.footerBtn {
  flex: 1;
}
