.tableRowProcessStatus {
  display: flex;
  align-items: center;
  gap: var(--marginXS);
}

.statusError {
  overflow: hidden;
  text-overflow: ellipsis;
}

.statusLoaderContainer {
  display: flex;
  gap: 1rem;
}
