.spinnerContainer,
.documentsErrorContent,
.tableRowExpanded {
  display: flex;
  align-items: center;
  margin-left: 2.8rem;
}

.spinnerContainer,
.documentsErrorContent {
  gap: 1rem;
  min-height: 40px;
}

.tableRowExpanded {
  gap: var(--paddingXXS);
  margin-bottom: var(--marginSM);
}
